import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney, convertDecimalToString } from '../../util/currency';
import { LINE_ITEM_CHILDREN_FEE, LINE_ITEM_NIGHT, LINE_ITEM_DAY, LINE_ITEM_HOUR, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemChildrenFeeMaybe = props => {
  const { lineItems, code, intl } = props;
  const isSession = props.isSessionBooking
  const isNightly = code === LINE_ITEM_NIGHT;
  const isDaily = code === LINE_ITEM_DAY;
  const isHourly = code === LINE_ITEM_HOUR;
  const translationKey = isSession ?
    'OrderBreakdown.baseUnitSessionChildren'
    : isNightly
      ? 'OrderBreakdown.baseUnitNightChildren'
      : isDaily
        ? 'OrderBreakdown.baseUnitDayChildren'
        : isHourly
          ? 'OrderBreakdown.baseUnitHourChildren'
          : 'OrderBreakdown.baseUnitQuantityChildren';

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  // const unitPurchase = lineItems.find(item => item.code === code && !item.reversal);
  const unitPurchase = lineItems.find(
    item => item.code === LINE_ITEM_CHILDREN_FEE && !item.reversal
  );

  const quantity = unitPurchase?.units
    ? unitPurchase.units.toString()
    : unitPurchase?.quantity
      ? unitPurchase.quantity.toString()
      : null;
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;
  const message = unitPurchase?.seats >= 1
    ? (<FormattedMessage id={`${translationKey}Seats`} values={{ unitPrice, quantity, seats: unitPurchase.seats }} />)
    : (<FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />)


  // const ChildrenFeeLineItem = lineItems.find(
  //   item => item.code === LINE_ITEM_CHILDREN_FEE && !item.reversal
  // );

  // const discount = ChildrenFeeLineItem?.percentage ;

  return unitPurchase ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        {message}
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemChildrenFeeMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemChildrenFeeMaybe;
