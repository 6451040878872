import merge from 'lodash/merge';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser, currentUserShowSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SAVE_COMPANY_DETAILS_REQUEST = 'app/CompanyDetailsPage/SAVE_COMPANY_DETAILS_REQUEST';
export const SAVE_COMPANY_DETAILS_SUCCESS = 'app/CompanyDetailsPage/SAVE_COMPANY_DETAILS_SUCCESS';
export const SAVE_EMAIL_ERROR = 'app/CompanyDetailsPage/SAVE_EMAIL_ERROR';
export const SAVE_PHONE_NUMBER_ERROR = 'app/CompanyDetailsPage/SAVE_PHONE_NUMBER_ERROR';

export const SAVE_COMPANY_DETAILS_CLEAR = 'app/CompanyDetailsPage/SAVE_COMPANY_DETAILS_CLEAR';

export const RESET_PASSWORD_REQUEST = 'app/CompanyDetailsPage/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'app/CompanyDetailsPage/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'app/CompanyDetailsPage/RESET_PASSWORD_ERROR';

// ================ Reducer ================ //

const initialState = {
  saveCompanyDetailsInProgress: false,
  CompanyDetailsChanged: false,

};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_COMPANY_DETAILS_REQUEST:
      return {
        ...state,
        saveCompanyDetailsInProgress: true,
        CompanyDetailsChanged: false,
      };
    case SAVE_COMPANY_DETAILS_SUCCESS:
      return { ...state, saveCompanyDetailsInProgress: false, CompanyDetailsChanged: true };

    case SAVE_COMPANY_DETAILS_CLEAR:
      return {
        ...state,
        saveCompanyDetailsInProgress: false,
        CompanyDetailsChanged: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveCompanyDetailsRequest = () => ({ type: SAVE_COMPANY_DETAILS_REQUEST });
export const saveCompanyDetailsSuccess = () => ({ type: SAVE_COMPANY_DETAILS_SUCCESS });


export const saveCompanyDetailsClear = () => ({ type: SAVE_COMPANY_DETAILS_CLEAR });


// ================ Thunks ================ //


/**
 * Update contact details, actions depend on which data has changed
 */
export const saveCompanyDetails = company => (dispatch, getState, sdk) => {
  dispatch(saveCompanyDetailsRequest());

  // const { email, currentEmail, phoneNumber, currentPhoneNumber, currentPassword } = company;


  const queryParams = {
    expand: true,
    include: ['profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  }

  return sdk.currentUser
    .updateProfile({ publicData: { company } }, queryParams)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      dispatch(saveCompanyDetailsSuccess(currentUser));
      dispatch(currentUserShowSuccess(currentUser));
    })

};


export const loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};
