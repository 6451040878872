import { convertMoneyToNumber } from '../util/currency';
export class LoggingAnalyticsHandler {
  trackPageView(url) {
    console.log('Analytics page view:', url);
  }
  login() {
    console.log('Analytics login');
  }
  signup() {
    console.log('Analytics signup');
  }
  viewItem(item) {
    console.log('Analytics viewItem', item);
  }
  selectItem(item) {
    console.log('Analytics selectItem', item);
  }
  beginCheckout(item) {
    console.log('Analytics beginCheckout', item);
  }
}

// Google Analytics 4 (GA4) using gtag.js script, which is included in util/includeScripts.js
export class GoogleAnalyticsHandler {
  trackPageView(canonicalPath, previousPath) {
    // GA4 property. Manually send page_view events
    // https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
    // Note 1: You should turn "Enhanced measurement" off.
    //         It attaches own listeners to elements and that breaks in-app navigation.
    // Note 2: If previousPath is null (just after page load), gtag script sends page_view event automatically.
    //         Only in-app navigation needs to be sent manually from SPA.
    // Note 3: Timeout is needed because gtag script picks up <title>,
    //         and location change event happens before initial rendering.
    if (previousPath && window.gtag) {
      window.setTimeout(() => {
        window.gtag('event', 'page_view', {
          page_path: canonicalPath,
        });
      }, 300);
    }
  }

  selectContent() {
    gtag("event", "select_content", {
      content_type: "product",
      content_id: "C_12345"
    });
  }

  login() {
    gtag("event", "login", {
      // method: "Google"
    });
  }

  signup() {
    gtag("event", "sign_up", {
      // method: "Google"
    });
  }

  viewItem(item) {
    gtag("event", "view_item", {
      currency: item.attributes.price?.currency,
      value: convertMoneyToNumber(item.attributes.price),
      items: [
        {
          item_id: item.id.uuid,
          item_name: item.attributes.title,
          // affiliation: "Google Merchandise Store",
          // coupon: "SUMMER_FUN",
          // discount: 2.22,
          // index: 0,
          // item_brand: "Google",
          // item_category: "Apparel",
          // item_category2: "Adult",
          // item_category3: "Shirts",
          // item_category4: "Crew",
          // item_category5: "Short sleeve",
          // item_list_id: "related_products",
          // item_list_name: "Related Products",
          // item_variant: "green",
          // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
          // price: 10.01,
          // quantity: 3
        }
      ]
    });
  }

  selectItem(item) {
    gtag("event", "select_content", {
      // currency: item.attributes.price.currency,
      // value: convertMoneyToNumber(item.attributes.price),
      // items: [
      //   {
      //     item_id: item.id.uuid,
      //     item_name: item.attributes.title,
      //   }
      // ]
    });
  }

  beginCheckout(item) {
    gtag("event", "begin_checkout", {
      // currency: "USD",
      // value: convertMoneyToNumber(item.transaction?.attributes?.payinTotal),
      items: [
        {
          // item.transaction.booking
          item_id: item.listing.id.uuid,
          item_name: item.listing.attributes.title,
        }
      ]
    });
  }

  addPaymentInfo() {
    gtag("event", "add_payment_info", {
      currency: "USD",
      value: 30.03,
      coupon: "SUMMER_FUN",
      payment_type: "Credit Card",
      items: [
        {
          item_id: "SKU_12345",
          item_name: "Stan and Friends Tee",
          affiliation: "Google Merchandise Store",
          coupon: "SUMMER_FUN",
          discount: 2.22,
          index: 0,
          item_brand: "Google",
          item_category: "Apparel",
          item_category2: "Adult",
          item_category3: "Shirts",
          item_category4: "Crew",
          item_category5: "Short sleeve",
          item_list_id: "related_products",
          item_list_name: "Related Products",
          item_variant: "green",
          location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
          price: 10.01,
          quantity: 3
        }
      ]
    });
  }
}
