/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// Note: The listingFields come from listingFields asset nowadays by default.
//       To use this built-in configuration, you need to change the overwrite from configHelper.js
//       (E.g. use mergeDefaultTypesAndFieldsForDebugging func)

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - listingTypeConfig (optional):  Relationship configuration against listing types.
 *   - limitToListingTypeIds:         Indicator whether this listing field is relevant to a limited set of listing types.
 *   - listingTypeIds:                An array of listing types, for which this custom listing field is
 *                                    relevant and should be added. This is mandatory if limitToListingTypeIds is true.
 * - categoryConfig (optional):     Relationship configuration against categories.
 *   - limitToCategoryIds:            Indicator whether this listing field is relevant to a limited set of categories.
 *   - categoryIds:                   An array of categories, for which this custom listing field is
 *                                    relevant and should be added. This is mandatory if limitToCategoryIds is true.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  {
    "key": "category",
    "scope": "public",
    "schemaType": "multi-enum",
    "enumOptions": [
      {
        "label": "Adventure",
        "option": "adventures"
      },
      {
        "label": "Birthday",
        "option": "birthday"
      },
      {
        "label": "Cooking",
        "option": "cooking"
      },
      {
        "label": "Discovery",
        "option": "curious"
      },
      {
        "label": "Nature",
        "option": "nature"
      },
      {
        "label": "Art/Culture",
        "option": "culture"
      },
      {
        "label": "Relax",
        "option": "relax"
      },
      {
        "label": "Sport",
        "option": "sport"
      },
      {
        "label": "Summer Camp",
        "option": "summer_camp"
      },
      {
        "label": "Water Sport",
        "option": "Water_sports"
      },
      {
        "label": "Beach",
        "option": "beaches"
      },
      {
        "label": "Hike / Walk",
        "option": "hiking"
      }
    ],
    "filterConfig": {
      "indexForSearch": true,
      "label": "Categories (*)",
      "filterType": "SelectMultipleFilter",
      "searchMode": "has_any",
      "group": "primary"
    },
    "showConfig": {
      "label": "Categories (*)",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Categories (*)",
      "isRequired": true
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "age",
    "scope": "public",
    "schemaType": "enum",
    "enumOptions": [
      {
        "label": "No limit",
        "option": "nolimit"
      },
      {
        "label": "from 1 year",
        "option": "1year"
      },
      {
        "label": "from 2 years",
        "option": "2years"
      },
      {
        "label": "from 3 years",
        "option": "3years"
      },
      {
        "label": "from 4 years",
        "option": "4years"
      },
      {
        "label": "from 5 years",
        "option": "5years"
      },
      {
        "label": "from 6 years",
        "option": "6years"
      },
      {
        "label": "from 7 years",
        "option": "7years"
      },
      {
        "label": "from 8 years",
        "option": "8years"
      },
      {
        "label": "from 9 years",
        "option": "9years"
      },
      {
        "label": "from 10 years",
        "option": "10years"
      },
      {
        "label": "from 11 years",
        "option": "11years"
      },
      {
        "label": "from 12 years",
        "option": "12years"
      }
    ],
    "filterConfig": {
      "indexForSearch": false,
      "label": "Age (*)",
      "filterType": "SelectMultipleFilter",
      "group": "primary"
    },
    "showConfig": {
      "label": "Age (*)",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Age (*)",
      "isRequired": true
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    },
  },
  {
    "key": "adult_age",
    "scope": "public",
    "schemaType": "text",
    "filterConfig": {
      "indexForSearch": false,
      "label": "Adult",
      "group": "primary"
    },
    "showConfig": {
      "label": "Adult",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Adult",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "children_age",
    "scope": "public",
    "schemaType": "text",
    "filterConfig": {
      "indexForSearch": false,
      "label": "Children",
      "group": "primary"
    },
    "showConfig": {
      "label": "Children",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Children",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "toddler_age",
    "scope": "public",
    "schemaType": "text",
    "filterConfig": {
      "indexForSearch": false,
      "label": "Toddler",
      "group": "primary"
    },
    "showConfig": {
      "label": "Toddler",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Toddler",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "age_range",
    "scope": "public",
    "schemaType": "multi-enum",
    "enumOptions": [
      {
        "label": "Toddlers (1 - 3yrs)",
        "option": "todds"
      },
      {
        "label": "Young kids (4 - 7yrs)",
        "option": "ykids"
      },
      {
        "label": "Kids (8 - 12yrs)",
        "option": "kids"
      },
      {
        "label": "Teenagers (12 - 18yrs)",
        "option": "teens"
      }
    ],
    "filterConfig": {
      "indexForSearch": true,
      "label": "Age range (*)",
      "filterType": "SelectMultipleFilter",
      "searchMode": "has_any",
      "group": "primary"
    },
    "showConfig": {
      "label": "Age range (*)",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Age range (*)",
      "isRequired": true
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "height",
    "scope": "public",
    "schemaType": "text",
    "filterConfig": {
      "indexForSearch": false,
      "label": "Minimum Height/ Weight",
      "group": "primary"
    },
    "showConfig": {
      "label": "Minimum Height/ Weight",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Minimum Height/ Weight",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "Mallorca_regions",
    "scope": "public",
    "schemaType": "multi-enum",
    "enumOptions": [
      {
        "label": "North & Sierra de Tramuntana",
        "option": "sierra-de-tramuntana"
      },
      {
        "label": "Northeast - Levante",
        "option": "Northeast"
      },
      {
        "label": "Central - Llano de Mallorca",
        "option": "central1"
      },
      {
        "label": "Palma de Mallorca",
        "option": "Palma-de-mallorca"
      },
      {
        "label": "Southeast - Migjorn",
        "option": "Southeast"
      }
    ],
    "filterConfig": {
      "indexForSearch": true,
      "label": "Location",
      "filterType": "SelectMultipleFilter",
      "searchMode": "has_any",
      "group": "primary"
    },
    "showConfig": {
      "label": "Location",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Location",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "environment",
    "scope": "public",
    "schemaType": "enum",
    "enumOptions": [
      {
        "label": "Outdoor",
        "option": "outdoor"
      },
      {
        "label": "Indoor",
        "option": "indoor"
      }
    ],
    "filterConfig": {
      "indexForSearch": true,
      "label": "Environment (*)",
      "filterType": "SelectMultipleFilter",
      "group": "secondary"
    },
    "showConfig": {
      "label": "Environment (*)",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Environment (*)",
      "isRequired": true
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "time",
    "scope": "public",
    "schemaType": "multi-enum",
    "enumOptions": [
      {
        "label": "In the morning, 6h - 12h",
        "option": "morning"
      },
      {
        "label": "In the afternoon, 12h - 17h",
        "option": "afternoon"
      },
      {
        "label": "In the evening, 17h - 24h",
        "option": "evening"
      }
    ],
    "filterConfig": {
      "indexForSearch": true,
      "label": "Time (*)",
      "filterType": "SelectMultipleFilter",
      "searchMode": "has_any",
      "group": "secondary"
    },
    "showConfig": {
      "label": "Time (*)",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Time (*)",
      "isRequired": true
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    },
    "listingTypeConfig": {
      "limitToListingTypeIds": true,
      "listingTypeIds": [
        "daily-booking",
        "daily-booking-instant",
        "hourly-booking-instant",
        "hourly-booking",
        "session-booking-instant",
        "session-booking"
      ]
    }
  },
  {
    "key": "time_band",
    "scope": "public",
    "schemaType": "multi-enum",
    "enumOptions": [
      {
        "label": "0-3 hours",
        "option": "less3h"
      },
      {
        "label": "3 - 5 hours",
        "option": "3to5hours"
      },
      {
        "label": "5 - 7 hours",
        "option": "5to7hours"
      },
      {
        "label": "Full day",
        "option": "full-day"
      },
      {
        "label": "5 days",
        "option": "5days"
      }
    ],
    "filterConfig": {
      "indexForSearch": true,
      "label": "Time frame(*)",
      "filterType": "SelectMultipleFilter",
      "searchMode": "has_any",
      "group": "secondary"
    },
    "showConfig": {
      "label": "Time frame(*)",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Time frame(*)",
      "isRequired": true
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    },
    "listingTypeConfig": {
      "limitToListingTypeIds": true,
      "listingTypeIds": [
        "session-booking-instant",
        "session-booking",
        "hourly-booking-instant",
        "hourly-booking",
        "daily-booking-instant",
        "daily-booking"
      ]
    }
  },
  {
    "key": "language",
    "scope": "public",
    "schemaType": "multi-enum",
    "enumOptions": [
      {
        "label": "Spanish",
        "option": "spanish"
      },
      {
        "label": "English",
        "option": "english"
      },
      {
        "label": "Catalan",
        "option": "catalan"
      },
      {
        "label": "French",
        "option": "french"
      },
      {
        "label": "German",
        "option": "german"
      },
      {
        "label": "Italian",
        "option": "italian"
      },
      {
        "label": "Portuguese",
        "option": "portuguese"
      }
    ],
    "filterConfig": {
      "indexForSearch": true,
      "label": "Language (*)",
      "filterType": "SelectMultipleFilter",
      "searchMode": "has_any",
      "group": "secondary"
    },
    "showConfig": {
      "label": "Language (*)",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Language (*)",
      "isRequired": true
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    },
    "listingTypeConfig": {
      "limitToListingTypeIds": true,
      "listingTypeIds": [
        "daily-booking-instant",
        "daily-booking",
        "hourly-booking-instant",
        "hourly-booking",
        "session-booking-instant",
        "session-booking"
      ]
    }
  },
  {
    "key": "cancellation_policy",
    "scope": "public",
    "schemaType": "enum",
    "enumOptions": [
      {
        "label": "Cancel up to 24 hours in advance for a full refund",
        "option": "full-refund-24h"
      },
      {
        "label": "Cancel up to 48 hours in advance for a full refund",
        "option": "full-refund-48h"
      },
      {
        "label": "Cancel up to 72 hours in advance for a full refund",
        "option": "full-refund-72hours"
      },
      {
        "label": "Cancel up to 7 days for a full refund",
        "option": "full-refund-7days"
      }
    ],
    "filterConfig": {
      "indexForSearch": false,
      "label": "Cancellation policy (*)",
      "filterType": "SelectMultipleFilter",
      "group": "primary"
    },
    "showConfig": {
      "label": "Cancellation policy (*)",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Cancellation policy (*)",
      "isRequired": true
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    },
    "listingTypeConfig": {
      "limitToListingTypeIds": true,
      "listingTypeIds": [
        "session-booking-instant",
        "session-booking",
        "daily-booking",
        "daily-booking-instant",
        "hourly-booking",
        "hourly-booking-instant"
      ]
    }
  },
  {
    "key": "group_size",
    "scope": "public",
    "schemaType": "text",
    "filterConfig": {
      "indexForSearch": false,
      "label": "Group size",
      "group": "primary"
    },
    "showConfig": {
      "label": "Group size",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Group size",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "includes",
    "scope": "public",
    "schemaType": "text",
    "filterConfig": {
      "indexForSearch": false,
      "label": "What does the activity include (*)",
      "group": "primary"
    },
    "showConfig": {
      "label": "What does the activity include (*)",
      "isDetail": true
    },
    "saveConfig": {
      "label": "What does the activity include (*)",
      "isRequired": true
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    },
    "listingTypeConfig": {
      "limitToListingTypeIds": true,
      "listingTypeIds": [
        "daily-booking",
        "daily-booking-instant",
        "hourly-booking",
        "hourly-booking-instant",
        "session-booking",
        "session-booking-instant"
      ]
    }
  },
  {
    "key": "not_include",
    "scope": "public",
    "schemaType": "text",
    "filterConfig": {
      "indexForSearch": false,
      "label": "What does the activity not include",
      "group": "primary"
    },
    "showConfig": {
      "label": "What does the activity not include",
      "isDetail": true
    },
    "saveConfig": {
      "label": "What does the activity not include",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "not_suitable",
    "scope": "public",
    "schemaType": "text",
    "filterConfig": {
      "indexForSearch": false,
      "label": "The activity is not suitable for (*)",
      "group": "primary"
    },
    "showConfig": {
      "label": "The activity is not suitable for (*)",
      "isDetail": true
    },
    "saveConfig": {
      "label": "The activity is not suitable for (*)",
      "isRequired": true
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    },
    "listingTypeConfig": {
      "limitToListingTypeIds": true,
      "listingTypeIds": [
        "daily-booking",
        "daily-booking-instant",
        "hourly-booking",
        "hourly-booking-instant",
        "session-booking",
        "session-booking-instant"
      ]
    }
  },
  {
    "key": "what_to_know",
    "scope": "public",
    "schemaType": "text",
    "filterConfig": {
      "indexForSearch": false,
      "label": "Important: What to know before booking",
      "group": "primary"
    },
    "showConfig": {
      "label": "Important: What to know before booking",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Important: What to know before booking",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "what_to_bring",
    "scope": "public",
    "schemaType": "text",
    "filterConfig": {
      "indexForSearch": false,
      "label": "Important: What to bring? (*)",
      "group": "primary"
    },
    "showConfig": {
      "label": "Important: What to bring? (*)",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Important: What to bring? (*)",
      "isRequired": true
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    },
    "listingTypeConfig": {
      "limitToListingTypeIds": false
    }
  },
  {
    "key": "meeting_point",
    "scope": "public",
    "schemaType": "text",
    "filterConfig": {
      "indexForSearch": false,
      "label": "The meeting point (*)",
      "group": "primary"
    },
    "showConfig": {
      "label": "The meeting point (*)",
      "isDetail": true
    },
    "saveConfig": {
      "label": "The meeting point (*)",
      "isRequired": true
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    },
    "listingTypeConfig": {
      "limitToListingTypeIds": true,
      "listingTypeIds": [
        "daily-booking",
        "daily-booking-instant",
        "hourly-booking",
        "hourly-booking-instant",
        "session-booking",
        "session-booking-instant"
      ]
    }
  },
  {
    "key": "accessibility",
    "scope": "public",
    "schemaType": "enum",
    "enumOptions": [
      {
        "label": "Yes",
        "option": "stroller-yes"
      },
      {
        "label": "No",
        "option": "stroller-no"
      }
    ],
    "filterConfig": {
      "indexForSearch": false,
      "label": "Stroller accessibility",
      "filterType": "SelectMultipleFilter",
      "group": "primary"
    },
    "showConfig": {
      "label": "Stroller accessibility",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Stroller accessibility",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "Changing_table",
    "scope": "public",
    "schemaType": "enum",
    "enumOptions": [
      {
        "label": "Yes",
        "option": "changingtable-yes"
      },
      {
        "label": "No",
        "option": "changingtable-no"
      }
    ],
    "filterConfig": {
      "indexForSearch": false,
      "label": "Changing Table",
      "filterType": "SelectMultipleFilter",
      "group": "primary"
    },
    "showConfig": {
      "label": "Changing Table",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Changing Table",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "picnic_area",
    "scope": "public",
    "schemaType": "enum",
    "enumOptions": [
      {
        "label": "Yes",
        "option": "picnic-yes"
      },
      {
        "label": "No",
        "option": "picnic-no"
      }
    ],
    "filterConfig": {
      "indexForSearch": false,
      "label": "Picnic area",
      "filterType": "SelectMultipleFilter",
      "group": "primary"
    },
    "showConfig": {
      "label": "Picnic area",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Picnic area",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "parking",
    "scope": "public",
    "schemaType": "enum",
    "enumOptions": [
      {
        "label": "Yes",
        "option": "parking-yes"
      },
      {
        "label": "No",
        "option": "parking-no"
      }
    ],
    "filterConfig": {
      "indexForSearch": false,
      "label": "Parking",
      "filterType": "SelectMultipleFilter",
      "group": "primary"
    },
    "showConfig": {
      "label": "Parking",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Parking",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "equipment_kids",
    "scope": "public",
    "schemaType": "enum",
    "enumOptions": [
      {
        "label": "Yes",
        "option": "equipment-yes"
      },
      {
        "label": "No",
        "option": "equipment-no"
      }
    ],
    "filterConfig": {
      "indexForSearch": false,
      "label": "Equipment specifically for kids",
      "filterType": "SelectMultipleFilter",
      "group": "primary"
    },
    "showConfig": {
      "label": "Equipment specifically for kids",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Equipment specifically for kids",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "breaks",
    "scope": "public",
    "schemaType": "enum",
    "enumOptions": [
      {
        "label": "Yes, snack included",
        "option": "breaks-snack-yes"
      },
      {
        "label": "Yes",
        "option": "breaks-yes"
      },
      {
        "label": "No",
        "option": "breaks-no"
      }
    ],
    "filterConfig": {
      "indexForSearch": false,
      "label": "Scheduled Breaks",
      "filterType": "SelectMultipleFilter",
      "group": "primary"
    },
    "showConfig": {
      "label": "Scheduled Breaks",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Scheduled Breaks",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "eating_area",
    "scope": "public",
    "schemaType": "enum",
    "enumOptions": [
      {
        "label": "Yes",
        "option": "eating-yes"
      },
      {
        "label": "No",
        "option": "eating-no"
      }
    ],
    "filterConfig": {
      "indexForSearch": false,
      "label": "Eating area",
      "filterType": "SelectMultipleFilter",
      "group": "primary"
    },
    "showConfig": {
      "label": "Eating area",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Eating area",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "playground",
    "scope": "public",
    "schemaType": "enum",
    "enumOptions": [
      {
        "label": "Yes",
        "option": "playground-yes"
      },
      {
        "label": "No",
        "option": "playground-no"
      }
    ],
    "filterConfig": {
      "indexForSearch": false,
      "label": "Playground",
      "filterType": "SelectMultipleFilter",
      "group": "primary"
    },
    "showConfig": {
      "label": "Playground",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Playground",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "toilets",
    "scope": "public",
    "schemaType": "enum",
    "enumOptions": [
      {
        "label": "Yes",
        "option": "toilets-yes"
      },
      {
        "label": "No",
        "option": "toilets-no"
      }
    ],
    "filterConfig": {
      "indexForSearch": false,
      "label": "Toilets",
      "filterType": "SelectMultipleFilter",
      "group": "primary"
    },
    "showConfig": {
      "label": "Toilets",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Toilets",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "shower",
    "scope": "public",
    "schemaType": "enum",
    "enumOptions": [
      {
        "label": "Yes",
        "option": "shower-yes"
      },
      {
        "label": "No",
        "option": "shower-no"
      }
    ],
    "filterConfig": {
      "indexForSearch": false,
      "label": "Shower",
      "filterType": "SelectMultipleFilter",
      "group": "primary"
    },
    "showConfig": {
      "label": "Shower",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Shower",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "public",
    "scope": "public",
    "schemaType": "enum",
    "enumOptions": [
      {
        "label": "Just for kids",
        "option": "for-kids"
      },
      {
        "label": "For kids, but parental presence is required",
        "option": "for-kids-with-parents"
      },
      {
        "label": "For the family",
        "option": "for-family"
      }
    ],
    "filterConfig": {
      "indexForSearch": true,
      "label": "Public (*)",
      "filterType": "SelectMultipleFilter",
      "group": "secondary"
    },
    "showConfig": {
      "label": "Public (*)",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Public (*)",
      "isRequired": true
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "pick_up",
    "scope": "public",
    "schemaType": "enum",
    "enumOptions": [
      {
        "label": "Yes",
        "option": "Pickup-yes"
      },
      {
        "label": "No",
        "option": "pickup-no"
      }
    ],
    "filterConfig": {
      "indexForSearch": false,
      "label": "Pick up service",
      "filterType": "SelectMultipleFilter",
      "group": "primary"
    },
    "showConfig": {
      "label": "Pick up service",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Pick up service",
      "isRequired": false
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    }
  },
  {
    "key": "Start_time",
    "scope": "public",
    "schemaType": "text",
    "filterConfig": {
      "indexForSearch": false,
      "label": "Start time (*)",
      "group": "primary"
    },
    "showConfig": {
      "label": "Start time (*)",
      "isDetail": true
    },
    "saveConfig": {
      "label": "Start time (*)",
      "isRequired": true
    },
    "categoryConfig": {
      "limitToCategoryIds": false
    },
    "listingTypeConfig": {
      "limitToListingTypeIds": true,
      "listingTypeIds": [
        "daily-booking",
        "daily-booking-instant",
        "hourly-booking",
        "hourly-booking-instant",
        "session-booking",
        "session-booking-instant"
      ]
    }
  }
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: The listingTypes come from listingTypes asset nowadays by default.
//         To use this built-in configuration, you need to change the overwrite from configHelper.js
//         (E.g. use mergeDefaultTypesAndFieldsForDebugging func)
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields These are tied to transaction processes. Different processes have different flags.
 *                        E.g. default-inquiry can toggle price and location to true/false value to indicate,
 *                        whether price (or location) tab should be shown. If defaultListingFields.price is not
 *                        explicitly set to _false_, price will be shown.
 *                        If the location or pickup is not used, listing won't be returned with location search.
 *                        Use keyword search as main search type if location is not enforced.
 *                        The payoutDetails flag allows provider to bypass setting of payout details.
 *                        Note: customers can't order listings, if provider has not set payout details! Monitor
 *                        providers who have not set payout details and contact them to ensure that they add the details.
 */

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
    defaultListingFields: {
      location: true,
      payoutDetails: true,
    },
  },
  {
    listingType: 'daily-booking-instant',
    label: 'Daily booking - Instant',
    transactionType: {
      process: 'instant-booking',
      alias: 'instant-booking/release-1',
      unitType: 'day',
    },
    defaultListingFields: {
      location: true,
      payoutDetails: true,
    },
  },
  {
    listingType: 'hourly-booking',
    label: 'Hourly booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'hour',
    },
  },
  {
    listingType: 'hourly-booking-instant',
    label: 'Hourly booking - Instant',
    transactionType: {
      process: 'instant-booking',
      alias: 'instant-booking/release-1',
      unitType: 'hour',
    },
  },
  {
    listingType: 'session-booking',
    label: 'Session booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'hour',
    },
  },
  {
    listingType: 'session-booking-instant',
    label: 'Session booking - Instant',
    transactionType: {
      process: 'instant-booking',
      alias: 'instant-booking/release-1',
      unitType: 'hour',
    },
  },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
