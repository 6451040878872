import { LOCATION_CHANGED } from '../ducks/routing.duck';
import { LOGIN_SUCCESS, SIGNUP_SUCCESS } from '../ducks/auth.duck';
import { SHOW_LISTING_SUCCESS, FETCH_LINE_ITEMS_SUCCESS } from '../containers/ListingPage/ListingPage.duck';
import { SET_INITIAL_VALUES, SPECULATE_TRANSACTION_SUCCESS } from '../containers/CheckoutPage/CheckoutPage.duck';

// Create a Redux middleware from the given analytics handlers. Each
// handler should have the following methods:
//
// - trackPageView(canonicalPath, previousPath): called when the URL is changed
export const createMiddleware = handlers => store => next => action => {
  const { type, payload } = action;

  if (type === LOCATION_CHANGED) {
    const previousPath = store?.getState()?.routing?.currentCanonicalPath;
    const { canonicalPath } = payload;
    handlers.forEach(handler => {
      handler.trackPageView(canonicalPath, previousPath);
    });
  }

  if (type === LOGIN_SUCCESS) {
    handlers.forEach(handler => {
      handler.login();
    });
  }

  if (type === SIGNUP_SUCCESS) {
    handlers.forEach(handler => {
      handler.signup();
    });
  }

  if (type === SHOW_LISTING_SUCCESS) {
    try {
      handlers.forEach(handler => {
        handler.viewItem(payload);
      });
    } catch (error) {
      console.error('analytics error', error)
    }

  }

  if (type === FETCH_LINE_ITEMS_SUCCESS) {
    try {
      handlers.forEach(handler => {
        handler.selectItem(payload);
      });
    } catch (error) {
      console.error('analytics error', error)
    }
  }

  // if (type === SPECULATE_TRANSACTION_SUCCESS) {
  if (type === SET_INITIAL_VALUES) {
    try {
      handlers.forEach(handler => {
        handler.beginCheckout(payload);
      });
    } catch (error) {
      console.error('analytics error', error)
    }
  }

  next(action);
};
