import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError, ExpandingTextarea } from '..';
import ReactFlagsSelect from "react-flags-select";

import css from './FieldTextI18nInput.module.css';
import { lang } from 'moment';

const CONTENT_MAX_LENGTH = 5000;

const COUNTRY_CODES = ["GB", "FR", "DE", "ES"];

class FieldTextI18nInputComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: 'en',
      input: props.input.value || '',

    };
  }
  handleLanguageChange = (code) => {
    let language = code === "GB" ? "EN" : code;
    this.setState({ selectedLanguage: language.toLowerCase() });
  };


  handleInputChange = (event) => {
    const { input } = this.props;
    const { selectedLanguage } = this.state;
    const newValue = typeof input.value === 'object' ? {
      ...input.value,
      [selectedLanguage]: event.target.value,
    } : { en: input.value, [selectedLanguage]: event.target.value, };
    // const newValue = {
    //   ...input.value,
    //   [selectedLanguage]: event.target.value,
    // };
    if (input.onChange) {
      input.onChange(newValue);
    }
    // this.setState({ input: event.target.value });
  };

  render() {
    const {
      rootClassName,
      className,
      inputRootClass,
      customErrorText,
      id,
      label,
      input,
      meta,
      onUnmount,
      isUncontrolled,
      inputRef,
      hideErrorMessage,
      ...rest
    } = this.props;

    const { selectedLanguage } = this.state;

    //this is an overrid to set the value to an object 
    // input.value = typeof input.value === 'object' ? input.value : { en: input.value };

    let newValue = typeof input.value === 'object' ? input.value : { en: input.value };

    // input.value = isOinput.value || {};

    if (label && !id) {
      throw new Error('id required when a label is given');
    }

    const { valid, invalid, touched, error } = meta;
    const isTextarea = input.type === 'textarea';

    const errorText = customErrorText || error;

    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError = !!customErrorText || !!(touched && invalid && error);

    const fieldMeta = { touched: hasError, error: errorText };

    // Textarea doesn't need type.
    const { type, ...inputWithoutType } = input;
    // Uncontrolled input uses defaultValue instead of value.
    const { value: defaultValue, ...inputWithoutValue } = input;
    // Use inputRef if it is passed as prop.
    const refMaybe = inputRef ? { ref: inputRef } : {};

    const inputClasses =
      inputRootClass ||
      classNames(css.input, {
        [css.inputSuccess]: valid,
        [css.inputError]: hasError,
        [css.textarea]: isTextarea,
      });
    const maxLength = CONTENT_MAX_LENGTH;

    // const inputValue = (input.value[selectedLanguage] ? input.value[selectedLanguage] : input.value) || ''
    // const inputValue = touched ? input.value[selectedLanguage] : input.value[selectedLanguage] ? input.value[selectedLanguage] : input.value || ''
    // const inputValue = (input.value[selectedLanguage] && !touched ? input.value[selectedLanguage] : input.value) || ''
    const inputProps = isTextarea
      ? {
        className: inputClasses,
        id,
        rows: 1,
        maxLength,
        ...refMaybe,
        ...inputWithoutType,
        // value: input.value[selectedLanguage],
        value: newValue[selectedLanguage] || '',
        onChange: this.handleInputChange,
        ...rest,
      }
      : isUncontrolled
        ? {
          className: inputClasses,
          id,
          type,
          // defaultValue: input.value[selectedLanguage] || '',
          defaultValue: newValue[selectedLanguage] || '',
          // defaultValue: this.state.inputValue,
          ...refMaybe,
          ...inputWithoutValue,
          onChange: this.handleInputChange,
          ...rest,
        }
        : {
          className: inputClasses, id, type,
          ...refMaybe,
          // value: input.value[selectedLanguage] || '',
          value: newValue[selectedLanguage] || '',
          onChange: this.handleInputChange, ...rest
        };
    // : { className: inputClasses, id, type, ...refMaybe, onChange: this.handleInputChange, ...input, ...rest };

    const classes = classNames(rootClassName || css.root, className);
    return (
      <div className={classes}>
        {label ? <label htmlFor={id}>{label}</label> : null}
        <ReactFlagsSelect
          countries={COUNTRY_CODES}
          selected={selectedLanguage == 'en' ? 'GB' : selectedLanguage.toUpperCase()}
          onSelect={this.handleLanguageChange}
          showSelectedLabel={false}
          showOptionLabel={false}
          className={css.root}
          optionsSize={20}
          fullWidth={true}
          selectButtonClassName="menu-flags-button"
        />
        {isTextarea ? <ExpandingTextarea {...inputProps} /> : <input {...inputProps} />}
        {hideErrorMessage ? null : <ValidationError fieldMeta={fieldMeta} />}
      </div>
    );
  }
};

FieldTextI18nInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
  inputRef: null,
};

FieldTextI18nInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,
  // a ref object passed for input element.
  inputRef: object,

  // Generated by final-form's Field component
  // input: shape({
  //   onChange: func.isRequired,
  //   // Either 'textarea' or something that is passed to the input element
  //   type: string.isRequired,
  // }).isRequired,

  input: shape({
    onChange: func,
    value: object.isRequired,
    type: string.isRequired,
  }).isRequired,
  meta: object.isRequired,
};

class FieldTextI18nInput extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldTextI18nInputComponent} {...this.props} />;
  }
}

export default FieldTextI18nInput;
