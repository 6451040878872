import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney, convertDecimalToString } from '../../util/currency';
import { LINE_ITEM_DISCOUNT, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemDiscountMaybe = props => {
  const { lineItems, intl } = props;

  const DiscountLineItem = lineItems.find(
    item => item.code === LINE_ITEM_DISCOUNT && !item.reversal
  );

  const discount = DiscountLineItem?.percentage ;

  return discount ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.Discount" values={{ discount: convertDecimalToString(discount) }} />
      </span>
      <span className={css.itemValue}>{formatMoney(intl, DiscountLineItem.lineTotal)}</span>
    </div>
  ) : null;
};

LineItemDiscountMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemDiscountMaybe;
