import { denormalisedResponseEntities } from '../util/data';
import { currentUserShowSuccess } from './user.duck'

export const SET_LOCALE = 'app/i18n/SET_LOCALE';

// ================ Reducer ================ //

const initialState = {
  locale: 'en',
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_LOCALE: {
      return {
        ...state,
        locale: payload.locale
      };
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //


export const setLocaleSuccess = (locale) => ({
  type: SET_LOCALE,
  payload: { locale },
});


export const setLocale = locale => {
  return (dispatch, getState, sdk) => {

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    }

    const currentUser = getState().user.currentUser;
    if (currentUser) {
      return sdk.currentUser
        .updateProfile({ publicData: { locale } }, queryParams)
        .then((response) => {
          const entities = denormalisedResponseEntities(response);
          if (entities.length !== 1) {
            throw new Error(
              "Expected a resource in the sdk.currentUser.updateProfile response",
            );
          }

          const currentUser = entities[0];
          dispatch(currentUserShowSuccess(currentUser));
          // dispatch(setLocaleSuccess(currentUser.attributes.profile.publicData.locale));
        });
      // .catch(e => dispatch(updateProfileError(storableError(e))));
    } else {
      dispatch(setLocaleSuccess(locale));
    }
  }
};


