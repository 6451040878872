import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
// export const TOS_ASSET_NAME = 'terms-of-service';
// export const PRIVACY_POLICY_ASSET_NAME = 'privacy-policy';
export const TOS_ASSET_NAME = 'terminos-y-condiciones';
export const PRIVACY_POLICY_ASSET_NAME = 'politica-de-privacidad';

const pages = {
  en: {
    terms: 'terms-and-conditions',
    privacy: 'privacy-policy'
  },
  es: {
    terms: 'terminos-y-condiciones',
    privacy: 'politica-de-privacidad'
  },
}


export const loadData = (params, search) => (dispatch, getState, sdk) => {

  const locale = getState()?.i18n?.locale;

  const tos = pages[locale]?.terms ?? pages['en'].terms;
  const privacy = pages[locale]?.privacy ?? pages['en'].privacy;

  const pageAsset = {
    termsOfService: `content/pages/${tos}.json`,
    privacyPolicy: `content/pages/${privacy}.json`,
  };
  return dispatch(fetchPageAssets(pageAsset, true));
};
