import { get } from 'lodash';
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { useDispatch, useSelector } from 'react-redux';
export const ASSET_NAME = 'landing-page';

export const loadData = (params, search) => (dispatch, getState) => {
  const locale = getState().i18n?.locale ?? 'en';
  let pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };

  switch (locale) {
    case 'es':
      pageAsset = { landingPage: `content/pages/inicio.json` };
      break;
    case 'fr':
      pageAsset = { landingPage: `content/pages/page-accueil.json` };
      break;
    case 'de':
      pageAsset = { landingPage: `content/pages/startseite.json` };
      break;
    default:
      break;
  }
  // const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
  // const locale = useSelector((state) => state.locale.locale);
  return dispatch(fetchPageAssets(pageAsset, true));
};
